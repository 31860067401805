import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";
import Spinner from "./components/spinner/Spinner";
import "./index.css";
import {
  initializationService,
  nextService,
} from "./services/bootstrap-services";
import reportWebVitals from "./reportWebVitals";



/**
 * Initializes the application by setting up the session, fetching public keys,
 * and rendering the main application component within a Redux provider and Router.
 * @summary This function manages the initialization of the app, including session management and rendering.
 * @returns {Promise<void>} Returns a promise that resolves when the application has been initialized.
 */
const initialization = async () => {
  try {
    let result = await initializationService();
    localStorage.setItem("session", result.init);
    let publickey = await nextService({ data: "string" });
    localStorage.setItem("out", publickey.access_token);

    const App = lazy(() => import("./App"));

    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(

      <ReduxProvider store={store}>
        <Suspense fallback={<Spinner />}>
          <Router>
            <App />
          </Router>
        </Suspense>
      </ReduxProvider>

    );

    // Aquí registramos el Service Worker para Firebase
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker
    //     .register('/firebase-messaging-sw.js')  // Debe ser desde la raíz
    //     .then((registration) => {
    //       console.log('Service Worker registrado con éxito:', registration);
    //     })
    //     .catch((err) => {
    //       console.log('Error al registrar el Service Worker:', err);
    //     });
    // }
  } catch (error) {
    console.error(error);
  }
};


initialization();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
