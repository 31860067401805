import axios, { AxiosRequestConfig } from "axios";

import {
  createDecipheriv,
  createCipheriv,
  randomBytes,
} from "crypto-browserify";
import { SuperDescripter, SuperEncripter } from "../../utils/superapis";
import { useVerifySecurity } from "../../contexts/VerifySecurityContext";




interface CallApiOptions {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  token?: string;
  body?: any;
  headers?: any;
}

export const callApi = async ({
  url,
  method,
  token,
  body,
}: CallApiOptions): Promise<any> => {
  const localToken = validateToken(localStorage.getItem("session") || "");

  // if (process.env.REACT_APP_AAA === "true") {
  //   body = { data: encryptedaes256cbc(body, localToken ? localToken : "") };
  // }

  let options: AxiosRequestConfig = {
    method: method,
    url: url,
    headers: {
      Authorization: token ? "Bearer " + token : "",
      "Content-Type": "application/json",
    },
  };

  if (body !== null) {
    if (method !== "DELETE") {
      options.data = JSON.stringify(body);
    }
  }

  const responsecall = await axios(options);

  const json = responsecall.data;

  // if (process.env.REACT_APP_AAA === "true") {
  //   return await descryptedaes256cbc(json.data, localToken ? localToken : "");
  // }

  return json;
};

export const callApiFormData = async ({
  url,
  method,
  token,
  body,
}: CallApiOptions): Promise<any> => {
  const localToken = validateToken(localStorage.getItem("session") || "");

  let options: AxiosRequestConfig = {
    method: method,
    url: url,
    headers: {
      Authorization: token ? "Bearer " + token : "",
      'Content-Type': 'multipart/form-data'
    },
    data: body
  };


  

  const responsecall = await axios(options);

  const json = responsecall.data;



  return json;
};



async function validateToken(token: string): Promise<string | undefined> {
  if (token) {
    let b = 0;
    let des = "";
    try {
      let dat = token;
      while (b < dat.length - 20) {
        des += dat.charAt(b);
        b += 21;
      }
      return des;
    } catch (error) {
      console.error("Error while validating token:", error);
      return undefined;
    }
  }
  return undefined;
}

//   function encryptedaes256cbc(data: any, privateKey: string): string | undefined {
//     try {
//       const iv = randomBytes(16);
//       console.log(iv.toString("hex"), data, privateKey);

//       const cipher = createCipheriv("aes-256-ctr", Buffer.from(privateKey, 'hex'), iv);
//       let encrypted = cipher.update(JSON.stringify(data));
//       encrypted = Buffer.concat([encrypted, cipher.final()]);

//       const encryptedText = `${iv.toString("hex")}:${encrypted.toString("hex")}`;
//       console.log(encryptedText);

//       return encryptedText;
//     } catch (error) {
//       console.log("ERROR", error);
//       return undefined;
//     }
//   };

//   export function descryptedaes256cbc(encryptedText: string, privateKey: string): any {
//     try {
//       console.log("encryptedText", encryptedText);
//       console.log("privateKey", privateKey);

//       const encryptedArray = encryptedText.split(":");
//       const iv = Buffer.from(encryptedArray[0], "hex");
//       const encryptedData = Buffer.from(encryptedArray[1], "hex");

//       const decipher = createDecipheriv("aes-256-ctr", Buffer.from(privateKey, 'hex'), iv);
//       let decrypted = decipher.update(encryptedData);
//       decrypted = Buffer.concat([decrypted, decipher.final()]);

//       const decryptedText = decrypted.toString();
//       return JSON.parse(decryptedText);
//     } catch (error) {
//       console.log("ERROR_DESCRYPT", error);
//       return undefined; // Or handle the error as needed
//     }
//   };

export const authenticate = async (data: any, next: any) => {



  if (typeof window !== "undefined") {
    const token = await SuperEncripter(data.access_token)

    localStorage.setItem("aet", token);
    localStorage.setItem("meta", data.meta)




    next();
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }

  if (localStorage.getItem("aet")) {
    return true;
  } else {
    return false;
  }
};

export async function getToken(): Promise<string> {
  const local = localStorage.getItem("aet");
  if (local === null) {
    return ""; // Manejar el caso en que el valor sea null
  }
  const tokenTemp = await SuperDescripter(local);
  return tokenTemp;
}


export function getPublicToken() {
  // const token = JSON.parse(localStorage.getItem("out"));
  const token = localStorage.getItem("out");
  return token ? token : "";
}

export async function getLocalToken(): Promise<string | undefined> {
  const sessionToken = localStorage.getItem("session") || "";
  const localToken = await validateToken(sessionToken);
  return localToken;
}